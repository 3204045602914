<template>
  <div class="main-container">
    
    <div class="container">

    <a href="https://www.antalpha.com/" target="_blank" class="clickable-div"> 
    <img alt="Vue logo" src="./assets/aa.jpg" class="image-with-spacing" width=50px height=50px> 
    </a>
     <a href="https://xai.games/" target="_blank" class="clickable-div"> 
      <div class="xai"> 
        <text :style="{ 
          fontWeight: 600, fontSize: '60px', color: '#000000'
        }">X </text>
        <svg xmlns="http://www.w3.org/2000/svg" width="61.9375" height="47.3375" viewBox="0 0 24.775 18.935">
          <path id="Path_2853" data-name="Path 2853" d="M12.388,5.517,18.05,15.3H6.725Zm0-5.517L0,18.935H24.775Z" fill="#f30919"/>
        </svg>
        <text :style="{ 
          fontWeight: 600, fontSize: '60px', color: '#000000'
        }"> I</text>
      </div>
    </a>
    </div>  
    <div class="divider"></div> <!-- 间隔线 -->
    <div class="text-container">
      <p>YAuqwKcqcyPp5E3tp9vVoeBVx6gURQyV0XhwNaooWS2k/2VWHtrUuZdDdBiBRMq62Qf6cKtlqn5aGTqIq0jZJRbEcd0dkmlHLcrFJ96+/8FHu4jGabROamNoQxG5xcDpiwHi3WmI1pUaZVumUUXo+P+av0gKxemR2OSyKIOM8ERfQAWzZkDUw+dvo7Ga4PIsIwcyJ9rTjeq4S7fyD6qym9tEK2vOyhAAab7s9qC9TFnGbFkeSI/NY3tOrmhEqee0qwRz7eCR5Uf2sZBpYEGHAHadBFhoGPJCpQyBFu673o4kEWgMMKE7t9S4KR/Dgji2RAsI+xUaz3Wdvwfeoa/SAPdCd4j6PoqTuD/b74KiL9TqHtEQgk//j25bZK2778n5yA==
      </p>
    </div>
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background-image: url('./assets/xai-bg.png'); /* 设置背景图片 */
  background-size: cover; /* 填充整个屏幕 */
  background-repeat: no-repeat; /* 不重复平铺 */
}

.main-container {
  width: 400px; /* 设置容器宽度 */
  margin: 200px auto; /* 左右居中，距离顶部 100px */
  background-color: #ffffff; /* 白色背景 */
  border-radius: 5px; /* 圆角 5px */
  padding: 20px; /* 内边距，根据需要调整 */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中对齐 */
}

.xai {
  margin-left: 100px; /* 设置间距 */
}

.divider {
  border-bottom: 1px solid #ccc; /* 间隔线样式 */
  width: 100%; /* 横向铺满 */
  margin-top: 20px; /* 上边距 */
}

.text-container {
  margin: 10px 0; /* 上下 10px 间距 */
}

.text-container p {
  margin: 20px; /* 文本与容器边缘间距为 0 */
  word-wrap: break-word; /* 自动换行 */
  overflow-wrap: break-word; /* 考虑老版本浏览器的支持 */
  font-weight: bold;
}

.clickable-div {
  /* 这里添加你想要的样式 */
  cursor: pointer; /* 鼠标指针样式为手型 */
  text-decoration: none; /* 移除下划线 */
}
</style>
